import * as types from '@/store/mutation-types'
import { isPast, format, parseISO } from 'date-fns'
import store from '@/store/index'
import router from '@/router'
import { es, zhCN } from 'date-fns/locale'

const localesDateFns = {
  es,
  cn: zhCN,
}

export const getFormat = (date, formatStr) => {
  return format(parseISO(date), formatStr, {
    locale: localesDateFns[window.__localeId__],
  })
}

export const formatErrorMessages = (translationParent, msg) => {
  const errorArray = []
  // Check for error msgs
  if (msg !== null) {
    const json = JSON.parse(JSON.stringify(msg))
    // If error message is an array, then we have mutiple errors
    if (Array.isArray(json)) {
      json.map((error) => {
        errorArray.push(`${error.msg}`)
      })
    } else {
      // Single error
      errorArray.push(`${msg}`)
    }
    return errorArray
  }
  // all good, return null
  return null
}

export const buildPayloadPagination = (pagination, search) => {
  const { page, itemsPerPage } = pagination
  let { sortDesc, sortBy } = pagination

  // When sorting you always get both values
  if (sortBy.length === 1 && sortDesc.length === 1) {
    // Gets order
    sortDesc = sortDesc[0] === true ? -1 : 1
    // Gets column to sort on
    sortBy = sortBy ? sortBy[0] : ''
  }

  let query = {}

  // If search and fields are defined
  if (search) {
    query = {
      sort: sortBy,
      order: sortDesc,
      page,
      limit: itemsPerPage,
      filter: search.query,
      fields: search.fields,
    }
  } else {
    // Pagination with no filters
    query = {
      sort: sortBy,
      order: sortDesc,
      page,
      limit: itemsPerPage,
    }
  }
  return query
}

// Catches error connection or any other error (checks if error.response exists)
export const handleError = (error, commit) => {
  let errMsg = ''
  // Resets errors in store
  commit(types.SHOW_LOADING, false)
  commit(types.ERROR, null)
  // Checks if unauthorized
  errMsg = error.response ? error.response.data.message : '잘못된 호출입니다.'
  setTimeout(() => {
    return errMsg
      ? commit(types.ERROR, errMsg)
      : commit(types.SHOW_ERROR, false)
  }, 0)
  // }
  if (error.response === undefined) {
    return
  }
  if (error.response.status === 401) {
    setTimeout(() => {
      sessionStorage.removeItem('tokenValue')
      sessionStorage.removeItem('tokenExpirations')
      sessionStorage.removeItem('userInfo')

      router.push({
        path: '/login',
      })
    }, 1000)
  } else if (
    error.response.status === 403 ||
    error.response.status === 404 ||
    error.response.status === 405
  ) {
    setTimeout(() => {
      router.push({
        path: '/login',
      })
    }, 1000)
  }
}

export const buildSuccess = (
  msg,
  commit,
  resolve,
  resolveParam = undefined,
) => {
  commit(types.SHOW_LOADING, false)
  commit(types.SUCCESS, null)
  setTimeout(() => {
    return msg ? commit(types.SUCCESS, msg) : commit(types.SHOW_SUCCESS, false)
  }, 0)
  commit(types.ERROR, null)
  resolve(resolveParam)
}

// Checks if tokenExpiration in localstorage date is past, if so then trigger an update
export const checkIfTokenNeedsRefresh = () => {
  // Checks if time set in localstorage is past to check for refresh token
  if (
    sessionStorage.getItem('tokenValue') !== null &&
    sessionStorage.getItem('tokenExpirations') !== null
  ) {
    if (
      isPast(
        new Date(
          parseISO(JSON.parse(sessionStorage.getItem('tokenExpirations'))) *
          1000,
        ),
      )
    ) {
      store.dispatch('refreshToken')
    }
  }
}
