import axios from '@/utils/request'

export default {
  getGridSetting(payload) {
    return axios.get('/bk/gridGen', {
      params: payload,
    })
  },
  getGridList(payload) {
    return axios.post('/bk/gridList', payload)
  },
  setGridUpdate(payload) {
    return axios.post('/bk/gridUpdate', payload)
  },
  setGridDel(payload) {
    return axios.post('/bk/gridUpdate', payload)
  },
}
