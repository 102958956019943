import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { format } from 'date-fns'

import Layout from '@/layouts/Layout'

const routes = [
  {
    path: '/login',
    name: '로그인',
    component: () => import('@/views/Login'),
  },
  {
    path: '/phaList',
    name: '약국 거래명세서 리스트',
    component: () => import('@/views/sot/PhaList'),
  },
  {
    path: '/wholeList',
    name: '도매상 거래명세서 리스트',
    component: () => import('@/views/sot/WholeList'),
  },
  {
    path: '/soTView',
    name: '거래명세서 상세',
    component: () => import('@/views/sot/View'),
  },
  {
    path: '/testPhaList',
    name: '테스트 약국리스트',
    component: () => import('@/views/test/testPhaList'),
  },
  {
    path: '/testWholeList',
    name: '테스트 도매상리스트',
    component: () => import('@/views/test/testWholeList'),
  },
  {
    path: '/testView',
    name: '테스트 뷰',
    component: () => import('@/views/sot/TestView'),
  },
  {
    path: '/soTDetail',
    name: '거래명세서 상세(PDF)',
    component: () => import('@/views/sot/Detail'),
  },
  {
    path: '/',
    component: Layout,
    name: '홈',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/dashboard',
        name: '대시보드',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/crypto',
        name: '암/복호화',
        component: () => import('@/views/crypto/Crypto'),
      },
      {
        path: '/adminList',
        name: 'Admin 관리',
        component: () => import('@/views/admin/List'),
      },
      {
        path: '/adminWrite',
        name: 'Admin 등록',
        component: () => import('@/views/admin/Write'),
      },
      {
        path: '/certificate',
        name: '공동인증서 관리',
        component: () => import('@/views/certificate/View'),
      },
      {
        path: '/sotList',
        name: '거래명세서 리스트',
        component: () => import('@/views/sot/List'),
      },
      {
        path: '/sotWIList',
        name: '도매상 월별 거래명세서 발급건수',
        component: () => import('@/views/sot/WholesaleIssueList'),
      },
      {
        path: '/sotAdminView',
        name: '거래명세서 상세 관리자',
        component: () => import('@/views/sot/AdminView'),
        props: true,
      },
      {
        path: '/logList',
        name: '로그 리스트',
        component: () => import('@/views/iFLog/List'),
        props: true,
      },
      {
        path: '/logView',
        name: '로그 상세',
        component: () => import('@/views/iFLog/View'),
        props: true,
      },
      {
        path: '/gridList',
        name: '화면속성관리',
        component: () => import('@/views/grid/List'),
        props: true,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const token = sessionStorage.getItem('tokenValue')
  const tokenExpire = sessionStorage.getItem('tokenExpirations')

  if (to.fullPath === '/' && token !== null) {
    return next('/dashBoard')
  } else if (to.fullPath === '/' && token === null) {
    return next('/login')
  }
  if (
    requiresAuth &&
    (token === null ||
      token === 'null' ||
      tokenExpire < JSON.stringify(format(new Date(), 't')))
  ) {
    return next('/Login')
  }

  return next()
})

export default router
