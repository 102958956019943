import axios from '@/utils/request'

export default {
  getEncrypt(payload) {
    return axios.post('/bk/encrypt', payload)
  },
  getDecrypt(payload) {
    return axios.post('/bk/decrypt', payload)
  },
}
