import axios from '@/utils/request'

export default {
  setCertInfo(payload) {
    return axios.post('/bk/cert/create', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
  getCertInfo(payload) {
    return axios.get('/bk/certInfo', payload)
  },
}
