import * as types from '@/store/mutation-types'
import api from '@/services/api/cert'
import { handleError } from '@/utils/utils.js'

const getters = {
  certInfo: (state) => state.certInfo,
  certResult: (state) => state.certResult,
}

const actions = {
  setCertInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .setCertInfo(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.CERT_RESULT, response.data.resultCode)

            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getCertInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .getCertInfo(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.CERT_INFO, response.data.certInfo)
            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
}

const mutations = {
  [types.CERT_INFO](state, certInfo) {
    state.certInfo = certInfo
  },
  [types.CERT_RESULT](state, result) {
    state.certResult = result
  },
}

const state = {
  certInfo: {},
  certResult: '',
}

export default {
  state,
  getters,
  actions,
  mutations,
}
