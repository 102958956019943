<template>
  <div>
    <AppHeader />
    <AppSidebar />
    <div class="wrapper">
      <CContainer>
        <router-view />
      </CContainer>
      <SuccessMessage />
      <ErrorMessage />
      <LoadingBar :loading="this.$store.state.loading.showLoading"></LoadingBar>
      <Alert ref="error" />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppHeader from '@/components/Header.vue'
import AppSidebar from '@/components/SidebarMenu.vue'
import LoadingBar from '@/views/components/Loading'
import * as types from '@/store/mutation-types'

export default {
  name: 'Layout',
  components: {
    AppHeader,
    AppSidebar,
    CContainer,
    LoadingBar,
  },
  computed: {
    showError() {
      return this.$store.state.error.showErrorMessage
    },
    errorMsg() {
      return this.$store.state.error.errorMessage
    },
  },
  watch: {
    showError: function (newValue, oldValue) {
      if (newValue === true) {
        this.modalOpen()
      }
    },
  },
  data() {
    return {
      userid: '',
      password: '',
      result: '',
      liveExampleVisible: false,
    }
  },
  methods: {
    modalOpen() {
      //let Msg = this.$store.state.auth.userMsg
      this.$refs.error.open('error', '', this.errorMsg)
      this.$store.commit(types.SHOW_ERROR, false)
    },
  },
}
</script>
