import * as types from '@/store/mutation-types'
import api from '@/services/api/sot'
import { handleError } from '@/utils/utils.js'

const getters = {
  sotList: (state) => state.sotList,
  sotListCount: (state) => state.sotListCount,
  sotView: (state) => state.sotView,
  wholeIssueList: (state) => state.wholeIssueList,
  wholeIssueListCount: (state) => state.wholeIssueListCount,
  pdf: (state) => state.pdf,
  verify: (state) => state.verify,
  sotId: (state) => state.sotId,
  requestor: (state) => state.requestor,
  detail: (state) => state.detail,
  item: (state) => state.item,
}

const actions = {
  getSotList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getSotList(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SOT_LIST, response.data.sotList)
            commit(types.SOT_LIST_CNT, response.data.totalcount)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getISotList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getISotList(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SOT_LIST, response.data.sotList)
            commit(types.SOT_LIST_CNT, response.data.totalcount)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getSotView({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getSotView(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SOT_VIEW, response.data.sotHtml)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getWholeIssueList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getWholeIssueList(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SOT_WHOLE_ISSUE, response.data.wholeIss)
            commit(types.SOT_WHOLE_ISSUE_CNT, response.data.totalcount)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getPdf({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getPdf(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SOT_PDF, response.data)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getVerify({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getVerify(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SOT_VERIFY, response.data.verify)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getSotDetail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getSotDetail(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SOT_DETAIL, response.data.soTDetail)
            commit(types.SOT_ITEM, response.data.sotItems)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getPdfMobile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getPdfMobile(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.SOT_PDF, response.data)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
}

const mutations = {
  [types.SOT_LIST](state, sotList) {
    state.sotList = sotList
  },
  [types.SOT_LIST_CNT](state, count) {
    state.sotListCount = count
  },
  [types.SOT_VIEW](state, html) {
    state.sotView = html
  },
  [types.SOT_WHOLE_ISSUE](state, wholeIssueList) {
    state.wholeIssueList = wholeIssueList
  },
  [types.SOT_WHOLE_ISSUE_CNT](state, count) {
    state.wholeIssueListCount = count
  },
  [types.SOT_PDF](state, pdf) {
    state.pdf = pdf
  },
  [types.SOT_VERIFY](state, verify) {
    state.verify = verify
  },
  [types.SOT_ID](state, sotId) {
    state.sotId = sotId
  },
  [types.SOT_REQUESTOR](state, requestor) {
    state.requestor = requestor
  },
  [types.SOT_DETAIL](state, detail) {
    state.detail = detail
  },
  [types.SOT_ITEM](state, item) {
    state.item = item
  },
}

const state = {
  sotList: {},
  sotView: '',
  sotListCount: 0,
  wholeIssueList: {},
  wholeIssueListCount: 0,
  pdf: {},
  verify: '',
}

export default {
  state,
  getters,
  actions,
  mutations,
}
