import axios from 'axios'
import store from '@/store/index'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  //timeout: 1000 * 60 * 3,
  //headers: {
  //    'Content-Type': 'application/json;charset=UTF-8'
  //}
})

request.interceptors.request.use(
  (config) => {
    store.commit('SHOW_LOADING', true)
    let token = sessionStorage.getItem('tokenValue')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

request.interceptors.response.use((config) => {
  store.commit('SHOW_LOADING', false)

  return config
})

export default request
