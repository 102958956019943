<template>
  <header>
    <div class="header-cont">
      <h1>
        <router-link :to="`/dashboard`">
          <img
            src="@/assets/images/logo_w.png"
            alt=""
            @click="$store.commit('toggleSidebar')"
          />
        </router-link>
      </h1>
      <ul>
        <!--
        <li class="mypage">
          <a href="#none"
            ><font-awesome-icon
              icon="user"
              style="margin-right: 6px"
            />마이페이지</a
          >
        </li>-->
        <li class="logout" @click="userLogout()">
          <font-awesome-icon
            icon="arrow-right-from-bracket"
            style="margin-right: 6px"
          />로그아웃
        </li>
      </ul>
    </div>
  </header>
  <!-- <CHeader>
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <Breadcrumb />
      </CHeaderNav>
      <CHeaderNav>
        <HeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
  </CHeader> -->
</template>

<script>
// import Breadcrumb from './Breadcrumb'
// import HeaderDropdownAccnt from './HeaderDropdownAccnt'
// import { logo } from '@/assets/brand/logo'
// export default {
//   name: 'AppHeader',
//   components: {
//     Breadcrumb,
//     // HeaderDropdownAccnt,
//   },
//   setup() {
//     return {
//       logo,
//     }
//   },
// }
export default {
  name: 'Header1',
  methods: {
    userLogout() {
      this.$store.dispatch('userLogout')
    },
  },
}
</script>
