import axios from '@/utils/request'

export default {
  userLogin(payload) {
    return axios.post('/bk/login', payload)
  },
  setLogOut() {
    return axios.post('/bk/logout')
  },
}
