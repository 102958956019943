import axios from '@/utils/request'

export default {
  getSotList(payload) {
    return axios.post('/bk/sotList', payload)
  },
  getISotList(payload) {
    return axios.post('/bk/iSotList', payload)
  },
  getSotView(payload) {
    return axios.post('/bk/sotView', payload)
  },
  getWholeIssueList(payload) {
    return axios.post('/bk/wholeIssList', payload)
  },
  getPdf(payload) {
    return axios.post('/bk/downloadPdfWeb', payload, { responseType: 'blob' })
  },
  getVerify(payload) {
    return axios.post('/bk/verifyWeb', payload)
  },
  getSotDetail(payload) {
    return axios.get('/bk/sotDetail', {
      params: payload,
    })
  },
  getPdfMobile(payload) {
    return axios.post('/bk/downloadPdfMobile', payload, {
      responseType: 'blob',
    })
  },
}
