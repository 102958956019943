import axios from '@/utils/request'

export default {
  getLogList(payload) {
    return axios.post('/bk/logList', payload)
  },
  getLogView(payload) {
    return axios.post('/bk/logView', payload)
  },
}
