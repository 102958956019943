import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/auth'
import { addMinutes, format } from 'date-fns'
import { handleError } from '@/utils/utils.js'
import store from '@/store/index'

const MINUTES_TO_CHECK_FOR_TOKEN_REFRESH = 120

const getters = {
  appTitle: (state) => state.appTitle,
  userInfo: (state) => state.userInfo,
  tokenValue: (state) => state.tokenValue,
}

const actions = {
  setLogin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .userLogin(payload)
        .then((response) => {
          if (response.status === 200) {
            sessionStorage.setItem(
              'userInfo',
              JSON.stringify(response.data.user),
            )
            sessionStorage.setItem('tokenValue', response.data.user.token)
            sessionStorage.setItem(
              'tokenExpirations',
              JSON.stringify(
                format(
                  addMinutes(new Date(), MINUTES_TO_CHECK_FOR_TOKEN_REFRESH),
                  't',
                ),
              ),
            )

            commit(types.SAVE_USER, response.data.user)
            commit(types.SAVE_TOKEN, response.data.token)

            router.push({ path: '/dashboard' })
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  userLogout({ commit }) {
    return new Promise((resolve) => {
      api
        .setLogOut()
        .then(() => {
          sessionStorage.removeItem('tokenValue')
          sessionStorage.removeItem('tokenExpirations')
          sessionStorage.removeItem('userInfo')
          commit(types.LOGOUT)
          store.commit('SHOW_LOADING', false)
          router.push({
            path: '/',
          })
          resolve()
        })
        .catch(() => {
          sessionStorage.removeItem('tokenValue')
          sessionStorage.removeItem('tokenExpirations')
          sessionStorage.removeItem('userInfo')
          commit(types.LOGOUT)
          store.commit('SHOW_LOADING', false)
          router.push({
            path: '/',
          })
        })
    })
  },
}

const mutations = {
  [types.SAVE_TOKEN](state, token) {
    state.tokenValue = token
    state.isTokenSet = true
  },
  [types.SAVE_USER](state, user) {
    state.userInfo = user
  },
  [types.LOGOUT](state) {
    state.userInfo = null
    state.tokenValue = null
  },
}

const state = {
  appTitle: 'UBCare',
  userInfo: null,
  tokenValue: JSON.parse(!!sessionStorage.getItem('tokenValue')) || null,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
