import * as types from '@/store/mutation-types'
import api from '@/services/api/admin'
import { buildSuccess, handleError } from '@/utils/utils.js'
import router from '@/router'

const getters = {
  adminList: (state) => state.adminList,
  adminListcount: (state) => state.adminListcount,
  idCheck: (state) => state.idCheck,
}

const actions = {
  getAdminList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .getAdminList(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ADMIN_LIST, response.data.adminList)
            commit(types.ADMIN_LIST_CNT, response.data.totalcount)

            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getIdCheck({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .getIdCheck(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ID_CHECK, response.data.idCheckYn)

            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  setUserInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .setUserInfo(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: '사용자가 등록되었습니다.',
              },
              commit,
              resolve,
              router.push({
                path: '/adminList',
              }),
            )

            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
}

const mutations = {
  [types.ADMIN_LIST](state, adminList) {
    state.adminList = adminList
  },
  [types.ADMIN_LIST_CNT](state, count) {
    state.adminListcount = count
  },
  [types.ID_CHECK](state, idCheck) {
    state.idCheck = idCheck
  },
}

const state = {
  adminList: {},
  adminListcount: 0,
  idCheck: 0,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
