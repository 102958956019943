// error
export const SHOW_ERROR = 'SHOW_ERROR'
export const ERROR = 'ERROR'
// success
export const SHOW_SUCCESS = 'SHOW_SUCCESS'
export const SUCCESS = 'SUCCESS'
// loading
export const SHOW_LOADING = 'SHOW_LOADING'
export const LOADING_MESSAGE = 'LOADING_MESSAGE'
// auth
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_USER = 'SAVE_USER'
export const LOGOUT = 'LOGOUT'
export const USER_CODE = 'USER_CODE'
export const USER_MSG = 'USER_MSG'
// admin
export const ADMIN_LIST = 'ADMIN_LIST'
export const ADMIN_LIST_CNT = 'ADMIN_LIST_CNT'
export const ID_CHECK = 'ID_CHECK'

// sot
export const SOT_LIST = 'SOT_LIST'
export const SOT_LIST_PARAM = 'SOT_LIST_PARAM'
export const SOT_LIST_CNT = 'SOT_LIST_CNT'
export const SOT_VIEW = 'SOT_VIEW'
export const SOT_WHOLE_ISSUE = 'SOT_WHOLE_ISSUE'
export const SOT_PDF = 'SOT_PDF'
export const SOT_VERIFY = 'SOT_VERIFY'
export const SOT_ID = 'SOT_ID'
export const SOT_REQUESTOR = 'SOT_REQUESTOR'
export const SOT_DETAIL = 'SOT_DETAIL'
export const SOT_ITEM = 'SOT_ITEM'

// cryto
export const ENC_DEC = 'ENC_DEC'

//dashboard
export const PHA_LIST = 'PHA_LIST'
export const WHOLE_LIST = 'WHOLE_LIST'
export const SOT_COUNT = 'SOT_COUNT'
export const PHA_COUNT = 'PHA_COUNT'
export const WHOLE_COUNT = 'WHOLE_COUNT'
export const SOT_WHOLE_ISSUE_CNT = 'SOT_WHOLE_ISSUE_CNT'

// cert
export const CERT_INFO = 'CERT_INFO'
export const CERT_RESULT = 'CERT_RESULT'

// log
export const LOG_LIST = 'SOT_LIST'
export const LOG_LIST_CNT = 'SOT_LIST_CNT'
export const LOG_VIEW = 'SOT_VIEW'

// grid
export const GRID_INIT = 'GRID_INIT'
export const GRID_LIST = 'GRID_LIST'
export const GRID_RESULT_CD = 'GRID_RESULT_CD'
