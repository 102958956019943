import axios from '@/utils/request'

export default {
  getAdminList(payload) {
    return axios.get('/bk/adminList', payload)
  },
  getIdCheck(payload) {
    return axios.get('/bk/idCheck', {
      params: payload,
    })
  },
  setUserInfo(payload) {
    return axios.post('/bk/createUser', payload)
  },
}
0
