import * as types from '@/store/mutation-types'
import api from '@/services/api/log'
import { handleError } from '@/utils/utils.js'

const getters = {
  logList: (state) => state.logList,
  logListCount: (state) => state.logListCount,
  logView: (state) => state.logView,
}

const actions = {
  getIFLogList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getLogList(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.LOG_LIST, response.data.logList)
            commit(types.LOG_LIST_CNT, response.data.totalCount)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getIFLogView({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getLogView(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.LOG_VIEW, response.data.logView)

            resolve()
          } else {
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
}

const mutations = {
  [types.LOG_LIST](state, logList) {
    state.logList = logList
  },
  [types.LOG_LIST_CNT](state, count) {
    state.logListCount = count
  },
  [types.LOG_VIEW](state, log) {
    state.logView = log
  },
}

const state = {
  logList: {},
  logView: '',
  logListCount: 0,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
