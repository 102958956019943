<template>
  <div class="pop-wrap" :class="modal ? '' : 'disable'">
    <div class="pop-box" style="width: 560px">
      <div class="pop-header" v-if="mode !== 'error'">
        <a href="#none" class="pop-close" @click="cancel()">닫기</a>
        {{ title }}
      </div>
      <div class="pop-cont">
        <p>
          {{ message }}
        </p>
      </div>
      <div class="pop-btn-area">
        <button
          class="btn-disabled btn-l mr10"
          type=""
          v-if="mode !== 'alert' && mode !== 'error'"
          @click="cancel()"
        >
          <font-awesome-icon icon="ban" class="mr10" />취소
        </button>
        <button class="btn-main btn-l fw800" @click="confirm()">
          <font-awesome-icon :icon="['far', 'circle-check']" class="mr10" />확인
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data() {
    return {
      modal: false,
      title: '',
      message: '',
      mode: '',
      status: '',
    }
  },
  methods: {
    open(mode, title, message, status) {
      this.title = title
      this.message = message
      this.mode = mode
      this.status = status
      this.modal = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    confirm() {
      this.modal = false
      this.$emit('modalevent', this.status)
    },
    cancel() {
      this.modal = false
      this.$emit('modalevent', 'cancel')
    },
  },
}
</script>
<style>
/*button*/
.switch_dis.btn-primary.disabled {
  background-color: #8a93a2;
  border-color: #8a93a2;
  cursor: auto;
}
.disable {
  display: none;
}
</style>
