import * as types from '@/store/mutation-types'
import api from '@/services/api/dashboard'
import { buildSuccess, handleError } from '@/utils/utils.js'
import router from '@/router'

const getters = {
  phaList: (state) => state.phaList,
  wholeList: (state) => state.wholeList,
  sotCount: (state) => state.sotCount,
  phaCount: (state) => state.phaCount,
  wholeCount: (state) => state.wholeCount,
}

const actions = {
  getDashboard({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .getDashboard(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.PHA_LIST, response.data.statPhaList)
            commit(types.WHOLE_LIST, response.data.statWhoList)
            commit(types.SOT_COUNT, response.data.statSotCount)
            commit(types.PHA_COUNT, response.data.statPhaCount)
            commit(types.WHOLE_COUNT, response.data.statWholeCount)

            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
}

const mutations = {
  [types.PHA_LIST](state, phaList) {
    state.phaList = phaList
  },
  [types.WHOLE_LIST](state, wholeList) {
    state.wholeList = wholeList
  },
  [types.SOT_COUNT](state, sotCount) {
    state.sotCount = sotCount
  },
  [types.PHA_COUNT](state, phaCount) {
    state.phaCount = phaCount
  },
  [types.WHOLE_COUNT](state, wholeCount) {
    state.wholeCount = wholeCount
  },
}

const state = {
  phaList: {},
  wholeList: {},
  sotCount: {},
  phaCount: {},
  wholeCount: {},
}

export default {
  state,
  getters,
  actions,
  mutations,
}
