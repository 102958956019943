<template>
  <VueAwesomeSideBar
    v-model:miniMenu="miniMenu"
    v-model:collapsed="collapsed"
    :menu="testMenu"
    vueRouterEnabel
  ></VueAwesomeSideBar>
</template>

<script setup>
import { ref } from 'vue'

const collapsed = ref(false)
const miniMenu = ref(false)

const testMenu = [
  {
    name: '거래명세서',
    children: [
      {
        name: '거래명세서 보관함',
        href: '/sotList',
      },
      {
        name: '도매상 월별 거래명세서 발급건수',
        href: '/sotWIList',
      },
    ],
  },
  {
    name: '암/복호화',
    href: '/crypto',
  },
  {
    name: 'Admin 관리',
    href: '/adminList',
  },
  {
    name: '공동인증서 관리',
    href: '/certificate',
  },
  {
    name: '로그 관리',
    href: '/logList',
  },
  {
    name: '화면속성 관리',
    href: '/gridList',
  },
]
</script>
