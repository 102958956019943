import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import alert from '@/views/components/Alert'
import loading from '@/views/components/Loading'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart, PieChart, CandlestickChart } from 'echarts/charts'
import vueAwesomeSidebar from 'vue-awesome-sidebar'
import 'echarts/lib/component/dataZoom'
import {
  ToolboxComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  VisualMapComponent,
} from 'echarts/components'
import VueAwesomePaginate from 'vue-awesome-paginate'

// import the necessary css file
import 'vue-awesome-paginate/dist/style.css'

import { Library, library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, far, fab)

use([
  LineChart,
  ToolboxComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BrushComponent,
  VisualMapComponent,
  CandlestickChart,
])

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueAwesomePaginate)
app.use(vueAwesomeSidebar)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('Alert', alert)
app.component('Loading', loading)
app.component('v-chart', ECharts)

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
