import * as types from '@/store/mutation-types'
import api from '@/services/api/grid'
import { handleError } from '@/utils/utils.js'

const getters = {
  gridInit: (state) => state.gridInit,
  gridList: (state) => state.gridList,
  gridResultCd: (state) => state.gridResultCd,
}

const actions = {
  getGridSetting({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .getGridSetting(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.GRID_INIT, response.data.grid)

            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  getGridList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .getGridList(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.GRID_LIST, response.data.grid)

            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  setGridUpdate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .setGridUpdate(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.GRID_RESULT_CD, response.data.resultCode)

            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
  setGridDel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_LOADING, true)
      api
        .setGridDel(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.GRID_RESULT_CD, response.data.resultCode)

            resolve()
          } else {
            commit(types.SHOW_LOADING, false)
            handleError(response.data.errorMessage, commit, reject)
          }
        })
        .catch((error) => {
          handleError(error, commit)
        })
    })
  },
}

const mutations = {
  [types.GRID_INIT](state, gridInit) {
    state.gridInit = gridInit
  },
  [types.GRID_LIST](state, gridList) {
    state.gridList = gridList
  },
  [types.GRID_RESULT_CD](state, resultCd) {
    state.gridResultCd = resultCd
  },
}

const state = {
  gridInit: {},
  gridList: {},
  gridResultCd: '',
}

export default {
  state,
  getters,
  actions,
  mutations,
}
